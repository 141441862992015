<script setup lang="ts">
interface Link {
    header: string;
    name: string;
    icon: string;
    iconInverse?: boolean;
    namesExtra?: string[];
    condition?: boolean;
}

const props = defineProps<{
    links: Link[];
}>();

const visibleLinks = computed((): Link[] => {
    return props.links.filter((l) => l.condition === undefined || l.condition);
});

const route = useRoute();
const isActiveRoute = (name: string, namesExtra?: string[]): boolean => {
    if (namesExtra && route.name && namesExtra.includes((route.name as string).split(':')[0])) {
        return true;
    }
    return route.name === name;
};
</script>

<template>
    <section v-if="visibleLinks.length" class="mt-4 mt-lg-0 d-print-none">
        <div class="d-none d-md-inline-flex btn-group btn-block mb-2 w-100">
            <nuxt-link
                v-for="{ header, name, icon, iconInverse, namesExtra } in visibleLinks"
                :key="name"
                :to="{ name }"
                :class="['btn', isActiveRoute(name, namesExtra) ? 'btn-primary' : 'btn-outline-primary']"
            >
                <Icon :v="icon" :inverse="iconInverse === undefined ? false : iconInverse || isActiveRoute(name)" />
                <T>{{ header }}</T>
            </nuxt-link>
        </div>
        <div class="d-block-force d-md-none btn-group-vertical btn-block mb-2 w-100">
            <nuxt-link
                v-for="{ header, name, icon, iconInverse, namesExtra } in visibleLinks"
                :key="name"
                :to="{ name }"
                :class="['btn', isActiveRoute(name, namesExtra) ? 'btn-primary' : 'btn-outline-primary']"
            >
                <Icon :v="icon" :inverse="iconInverse === undefined ? false : iconInverse || isActiveRoute(name)" />
                <T>{{ header }}</T>
            </nuxt-link>
        </div>
    </section>
</template>
